'use client';

import { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';

import theme from '@/utils/styles-variables';

const Template = ({ children }) => {
  useEffect(() => {
    function setWindowWidth() {
      document.documentElement.style.setProperty(
        '--window-width',
        `${document.documentElement.clientWidth}px`,
      );
    }
    setWindowWidth();

    window.addEventListener('resize', () => setWindowWidth());

    return () => {
      window.removeEventListener('resize', () => setWindowWidth());
    };
  }, []);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Template;
